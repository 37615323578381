
import React from 'react';
import '../../App.css';
import "./Navbar.css";
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import logo from "../../images/kfcBorder.png";
import { Link } from 'react-router-dom';
// import discordIcon from "../../images/Discord-Logo.png";
// import { BrowserView, MobileView } from 'react-device-detect';

function Navigation() {
    return (

        <Navbar className="nav-bar" expand="lg" sticky="top">
            <Container>
                <Navbar.Brand href="." className="d-inline-block align-top"><img src={logo} className="App-logo" alt="logo" /></Navbar.Brand>
                <h1 className="col" ><a href="/" className="nav-title">KFC Bucket Boy</a></h1>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Container className="nav-selections" >
                        <Nav className="m-auto" id="nav-top">
                            <Nav.Link as={Link} to="/" className="nav-text">Home</Nav.Link>
                            <Nav.Link as={Link} to="/about" className="nav-text">About</Nav.Link>
                            <NavDropdown
                                className="nav-dropdown"
                                title={
                                    <span className="nav-text">Commands</span>
                                }
                                id="nav-dropdown-dark-example">
                                <NavDropdown.Item className="nav-dropdown" as={Link} to="/commands">All</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item  className="nav-dropdown" as={Link} to="/commands">Work In Progress</NavDropdown.Item>
                            </NavDropdown>
                            {/* <BrowserView>
                            <Navbar.Brand href="." className="d-inline-block align-top">
                                    <img src={discordIcon} className="nav-discord-icon" alt="logo" />
                                </Navbar.Brand>
                            </BrowserView>
                            <MobileView>
                            <Navbar.Brand href="." className="d-inline-block align-top">
                                    <img src={discordIcon} className="nav-discord-icon" alt="logo" />
                                </Navbar.Brand>
                            </MobileView> */}
                            
                        </Nav>
                    </Container>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
import React from 'react';
import TitleBox from '../../components/Title/TitleBox';
import kaineLofi from "../../images/kaine_lofi.png";
// import "../css/about.css";


const Commands = () => {


  return (
    <div>

      {
        TitleBox(
          "Commands",
          "Learn all of my commands!",
          kaineLofi,
          5)
      }

      <h1 style={{
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
      }}>Soon to come more commands!</h1>
      <hr />
      <p style={{
        display: 'flex',
        justifyContent: 'center',
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: 'center',
        color: 'white',
        width: "85%"
      }}>
        Most basic usage is  <code style={{ margin: "2px 5px"}}>k!ping</code> !</p>
      <hr />

      <div
        className="badge-base LI-profile-badge linkedin-box"
        data-locale="en_US"
        data-size="large"
        data-theme="dark"
        data-type="HORIZONTAL"
        data-vanity="kaine-simpkins-753900220"
        data-version="v1">

      </div>

      <div
        itemScope
        itemType='http://schema.org/Person'
        className='fiverr-seller-widget'
        style={{ display: "inline-block" }}>

        <a
          itemProp='url'
          href="https://www.fiverr.com/kingofkarma"
          rel="noreferrer"
          target="_blank"
          style={{ display: "inline-block" }}>

          <div
            className='fiverr-seller-content'
            id='fiverr-seller-widget-content-a9b5408e-622a-49ca-b9cb-a7fe57fe7a2c'
            itemProp='contentURL'
            style={{ display: "none" }}>

          </div>

          <div
            id='fiverr-widget-seller-data'
            style={{ display: "none" }}>

            <div
              itemProp='name' >kingofkarma</div>

            <div
              itemScope
              itemType='http://schema.org/Organization'>
              <span
                itemProp='name'>Fiverr</span>
            </div>

            <div
              itemProp='jobtitle'>Seller</div>

            <div
              itemProp='description'>I'm a Programmer who does everything on the internet, I make bots for Discord and Twitch, Web development, Game development. I hope my services can provide a good amount to people out there! </div>
          </div>
        </a>
      </div>



    </div >

  );
};

export default Commands;

import React, { useEffect } from 'react';
import TitleBox from '../../components/Title/TitleBox';
import kaineTitle from "../../images/kaineTitle.png";
import "../css/globals.css";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      {
        TitleBox(
          "Privacy Policy",
          "Data privacy is important to us",
          kaineTitle,
          6
        )
      }


<hr />
      <p className="center-text" style={{
        width: "85%"
      }}>
        We only collect Message Content for AutoMod and XP module this is to prevent any spamming to
        cheat with points and for basic moderation purposes, These modules can be disabled by staff
        within a server and you can opt-out of collecting XP</p>

        <p className="center-text" style={{
        width: "40%"
      }}>
        Customization will be available on our dashboard in the far future</p>

      <hr />


    </div>
  );
};

export default PrivacyPolicy;
import React from 'react';
import TitleBox from '../../components/Title/TitleBox';
import kaineLofi from "../../images/kaine_lofi.png";
import "../css/about.css";
import "../css/globals.css";
import FetchBotStats from "../../utils/fetchAPI";


const About = () => {
  const data = FetchBotStats("https://api.bucketbot.dev/bot");
  console.log(data);
  const { bot } = data;
  return (
    <div>

      {
        TitleBox(
          "About Me",
          "This page tells you all there is to know about me!",
          kaineLofi,
          5)
      }



      <h1 className="center-text" style={{
        display: 'flex',
      }}>Your best friend, in the making!</h1>

      <div className="center-text" style={{ paddingInline: "50px" }} >
        <div className="button-div">
          <a className="button" target="_blank" rel='noreferrer' href="https://invite.bucketbot.dev">
            Invite me
          </a>
        </div>
        <div className="button-div">
          <a className="button" target="_blank" rel='noreferrer' href="https://support.bucketbot.dev">
            Support Server
          </a>
        </div>
      </div>

      <hr />
      <p className="center-text" style={{
        width: "85%"
      }}>
        Hi there! I'm Bucket Boy, I was created originally as a small project back in 2019 and have since become a much
        larger part of my developer's life. More info and passion will be put into this website soon but for now
        this is what we have!</p>

      <hr />

      <ul className="center-text" style={{
        fontSize: "25px"
      }}>
        <p><h2> Servers</h2><code>{bot ? bot.guilds ?? "NaN" : "NaN"}</code></p>
        <hr />
        <p><h2> Uptime</h2><code>{bot ? bot.uptime.time ?? "NaN" : "NaN"}</code></p>
      </ul>

    </div >

  );
};

export default About;



import React from 'react';
import TitleBox from '../../components/Title/TitleBox';
import kaineTitle from "../../images/kaineTitle.png";
import "../css/globals.css";

const Home = () => {



  return (
    <div>
      {
        TitleBox(
          "KFC Bucket Boy",
          "Multi-Purpose Bot + Your new best friend",
          kaineTitle,
          6
        )
      }

      <div className="center-text" style={{ paddingInline: "50px" }} >
        <div className="button-div">
          <a className="button" target="_blank" rel='noreferrer' href="https://invite.bucketbot.dev">
            Invite me
          </a>
        </div>
        <div className="button-div">
          <a className="button" target="_blank" rel='noreferrer' href="https://support.bucketbot.dev">
            Support Server
          </a>
        </div>
      </div>


      <span style={{
        padding: "10px 0px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: 'white',
      }}>
        <p style={{
          fontSize: "25px",
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          color: 'white',
        }}>Take a look around via the navigation bar as this website is still under development</p>
      </span>
    </div>
  );
};

export default Home;
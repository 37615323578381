
import React from 'react';
import './App.css';
import Navigation from './components/Navbar/NavbarElements';
import FooterBar from './components/Footer/FooterElements';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/js';
import About from './pages/js/about';
import Credits from './pages/js/credits';
import Commands from './pages/js/commands';
import Error404 from './pages/js/404';
import PrivacyPolicy from './pages/js/privacyPolicy';

function App() {
  return (
    <Router>
      <div className="App-base">
        <Navigation />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about' exact component={About} />
          <Route path='/commands' exact component={Commands} />
          <Route path='/credits/' exact component={Credits} />
          <Route path='/privacy-policy/' exact component={PrivacyPolicy} />
          <Route path='/404/' exact component={Error404} />
          <Route>
          <meta http-equiv="refresh" content="0; URL='/404'" />
          </Route>            
        </Switch>
        <FooterBar />
      </div>
    </Router>

  );
}

export default App;